import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import iconTidal from '../../assets/img/tidal.svg';

import logoTidal from '../../assets/img/logos/tidal.png';
import logoTikTok from '../../assets/img/logos/tiktok.png';
import logoAmazonMusic from '../../assets/img/logos/amazonmusic.svg';
import logoAppleMusic from '../../assets/img/logos/applemusic.png';
import logoDeezer from '../../assets/img/logos/deezer.svg';
import logoFacebook from '../../assets/img/logos/facebook.svg';
import logoiTunes from '../../assets/img/logos/itunes.png';
import logoNapster from '../../assets/img/logos/napster.svg';
import logoSpotify from '../../assets/img/logos/spotify.png';
import logoYoutube from '../../assets/img/logos/youtube.png';
import logoOfficialWebsite from '../../assets/img/logos/official_website.png';
import logoOfficialStore from '../../assets/img/logos/official_store.png';
import logoOfficialArtistWebsite from '../../assets/img/logos/official_artistwebsite.png';
import logoOfficialArtistStore from '../../assets/img/logos/official_artiststore.png';

import { faSpotify, faApple, faItunes, faDeezer, faYoutube, faAmazon, faFacebook, faTiktok, faNapster, faVk } from '@fortawesome/free-brands-svg-icons'
import { faCartShopping, faGlobe } from '@fortawesome/free-solid-svg-icons'

import Image from 'mui-image';

export let productFamilies = [
  {
    id: 'spotify',
    label: 'Spotify',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#1db954',
    icon: <FontAwesomeIcon icon={faSpotify} />,
    logo: logoSpotify
  },
  {
    id: 'appleMusic',
    label: 'Apple Music',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faApple} />,
    logo: logoAppleMusic
  },
  {
    id: 'itunes',
    label: 'iTunes',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faItunes} />,
    logo: logoiTunes
  },
  {
    id: 'deezer',
    label: 'Deezer',
    defaultTextColor: '#000000',
    defaultBackgroundColor: '#ffffff',
    icon: <FontAwesomeIcon icon={faDeezer} />,
    logo: logoDeezer
  },
  {
    id: 'youtube',
    label: 'YouTube',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#4267B2',
    icon: <FontAwesomeIcon icon={faYoutube} />,
    logo: logoYoutube
  },
  {
    id: 'facebook',
    label: 'Facebook',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#4267B2',
    icon: <FontAwesomeIcon icon={faFacebook} />,
    logo: logoFacebook
  },
  {
    id: 'tiktok',
    label: 'TikTok',
    defaultTextColor: '#FFFFFF',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faTiktok} />,
    logo: logoTikTok
  },
  {
    id: 'amazon',
    label: 'Amazon',
    defaultTextColor: '#232F3E',
    defaultBackgroundColor: '#05A0D1',
    icon: <FontAwesomeIcon icon={faAmazon} />,
    logo: logoAmazonMusic
  },
  {
    id: 'napster',
    label: 'Napster',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faNapster} />,
    logo: logoNapster
  },
  {
    id: 'vkontakte',
    label: 'vKontakte',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#4a76a8',
    icon: <FontAwesomeIcon icon={faVk} />
  },
  {
    id: 'tidal',
    label: 'Tidal',
    defaultTextColor: '#232F3E',
    defaultBackgroundColor: '#05A0D1',
    icon: <Image wrapperStyle={{display: 'inline-block'}} wrapperClassName="icon-tidal" src={iconTidal} />,
    logo: logoTidal
  },
  {
    id: 'officialWebsite',
    label: 'Official Website',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faGlobe} />,
    logo: logoOfficialWebsite
  },
  {
    id: 'officialStore',
    label: 'Official Store',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faCartShopping} />,
    logo: logoOfficialStore,
  },
  {
    id: 'officialArtistWebsite',
    label: 'Official Artist Website',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faGlobe} />,
    logo: logoOfficialArtistWebsite
  },
  {
    id: 'officialArtistStore',
    label: 'Official Artist Store',
    defaultTextColor: '#ffffff',
    defaultBackgroundColor: '#000000',
    icon: <FontAwesomeIcon icon={faCartShopping} />,
    logo: logoOfficialArtistStore,
  },
];

export let supportedIntegrations = [
  {id: 'spotify', slug: 'spotify', label: 'Spotify', active: true, productFamily: 'spotify', secondaryLabel: 'Streaming Service Sign In', type: 'Services'},
  {id: 'appleMusic', slug: 'apple-music', label: 'Apple Music', active: true, productFamily: 'appleMusic', secondaryLabel: 'Streaming Service Sign In', type: 'Services'},
  {id: 'deezer', slug: 'deezer', label: 'Deezer', active: true, productFamily: 'deezer', secondaryLabel: 'Streaming Service Sign In', type: 'Services'},
  {id: 'amazon', slug: 'amazon', label: 'Amazon', active: true, productFamily: 'amazon', secondaryLabel: 'Streaming Service Sign In', type: 'Services'},
  {id: 'napster', slug: 'napster', label: 'Napster', active: false, productFamily: 'napster', secondaryLabel: 'Streaming Service Sign In', type: 'Services'},
  {id: 'youTube', slug: 'youtube', label: 'YouTube', active: false, productFamily: 'youtube', secondaryLabel: 'Streaming Service Sign In', type: 'Services'},
  {id: 'facebook', slug: 'facebook', label: 'Facebook', active: false, productFamily: 'facebook', secondaryLabel: 'Social Sign In', type: 'Services'},
  {id: 'tiktok', slug: 'tiktok', label: 'TikTok', active: false, productFamily: 'tiktok', secondaryLabel: 'Social Sign In', type: 'Services'},
  {id: 'vkontakte', slug: 'vkontakte', label: 'VKontakte', active: false, productFamily: 'vkontakte', secondaryLabel: 'Social Sign In', type: 'Services'},

  
  {id: 'tidal', slug: 'tidal', label: 'Tidal', active: false, productFamily: 'tidal', secondaryLabel: 'Streaming Service Link', type: 'Links'},

  {id: 'amazon_digital', slug: 'amazon_digital', label: 'Amazon (Digital)', active: false, productFamily: 'amazon', secondaryLabel: 'Store Link', type: 'Links'},
  {id: 'amazon_vinyl', slug: 'amazon_vinyl', label: 'Amazon (Vinyl)', active: false, productFamily: 'amazon', secondaryLabel: 'Store Link', type: 'Links'},
  {id: 'amazon_cd', slug: 'amazon_cd', label: 'Amazon (CD)', active: false, productFamily: 'amazon', secondaryLabel: 'Store Link', type: 'Links'},
  

  {id: 'itunes', slug: 'itunes', label: 'iTunes', active: true, productFamily: 'itunes', secondaryLabel: 'Store Link', type: 'Links'},

  
  {id: 'officialStore', slug: 'store', label: 'Official Store', active: false, productFamily: 'officialStore', secondaryLabel: 'Link', type: 'Links'},
  {id: 'officialWebsite', slug: 'website', label: 'Official Website', active: false, productFamily: 'officialWebsite', secondaryLabel: 'Link', type: 'Links'},

  {id: 'officialArtistStore', slug: 'artist-store', label: 'Official Artist Store', active: false, productFamily: 'officialArtistStore', secondaryLabel: 'Link', type: 'Links'},
  {id: 'officialArtistWebsite', slug: 'artist-website', label: 'Official Artist Website', active: false, productFamily: 'officialArtistWebsite', secondaryLabel: 'Link', type: 'Links'},
  
    
];